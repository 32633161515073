/* grafics.css */

#graphic {
  padding: 20px;
  background: #b784b7;
}

.design-grid {
  max-width: 1200px;
  margin: 0 auto;
}

.title {
  font-size: 2em;
  margin-bottom: 10px;
  text-align: center;
  margin-top: 150px;
}

.design-description {
  font-size: 1.2em;
  margin-bottom: 20px;
  text-align: center;
  color: #ebebeb;
}

.design-items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding-bottom: 100px;
}

.design-item {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 0 10px #000080;
  transition: transform 0.3s ease;
  height: 400px; /* Set a fixed height for the design items */
}

.img-container {
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  height: 70%;
}

.design-items img {
  width: 100%;
  height: 100%;

  margin: 5px 5px 5px 0;
  border-radius: 8px 8px 0 0;
}

.design-info {
  font-size: 1.2em;
  margin-bottom: 10px;
  text-align: center;
  color: #ebebeb;
}

.button-container {
  position: absolute;
  bottom: 0;
  width: 100%;
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #ffa6004c;
  color: #000080;
  border: none;
  border-radius: 0 0 8px 8px;
  cursor: pointer;
}
.hem-btn-con {
  display: flex;
  padding: 10px;
  width: 250px;
  background-color: #ffa500;
  color: #000080;
  border: none;
  border-radius: 10px 0 10px 0;
  cursor: pointer;
}

.hem-btn {
  color: #ebebeb;
  background: none;
  width: 200px;
}
.hem-btn a {
  color: #ebebeb;
}
button:hover {
  color: #fff;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup img {
  max-width: 100%;
  max-height: 80vh;
  border-radius: 8px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #ffa500;
  font-size: 2em;
  cursor: pointer;
}
