.about-container {
  display: grid;
  grid-template-columns: 35% 53%;
  gap: 12%;
}

.about-portrait {
  height: fit-content;
  max-height: 40rem;
  border: 1rem solid #ffa60024;
  transition: var(--transition);
}

.about-portrait:hover {
  border-width: 0;
}

.about-cards {
  margin: 3rem 0 1.5rem;
  display: grid;

  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.card.about-card {
  text-align: center;
  background-color: #ffffff57;
}

.about-card-icon {
  font-size: 1.3rem;
  background-color: #fff;
  color: #000080;
  border-radius: var(--border-radius-3);
  width: fit-content;
  margin-inline: auto;
  padding: 0.6rem;
  display: flex;
  align-items: center;
  transition: var(--transition);
}

.card.about-card:hover .about-card-icon {
  background: #000080;
  color: #fff;
}

.card.about-card h5 {
  margin: 1rem 0;
}

.about-right p {
  margin-bottom: 0.8rem;
  color: aliceblue;
}

.about-right .btn {
  margin-top: 2.5rem;
}
.btn-read {
  background: #000080;
  color: var(--color-white);
  padding: 0.5rem;
  border-radius: 5px;
}

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
  .about-left {
    display: none;
  }

  .about-container {
    grid-template-columns: 1fr;
  }

  .about-right h2 {
    text-align: center;
  }
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
  .about-cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .about-right .btn {
    margin-top: 2rem;
  }
}
