header {
  margin-top: 5rem;
  height: calc(100vh - 5rem);
  display: grid;
  place-items: center;

  transition: var(--transition);
}
.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.header-profile {
  width: 18rem;
  height: 18rem;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1.5rem;
}
.header-container p {
  width: 35rem;
  text-align: center;
  margin: 0.6rem 0 2rem;
  color: aliceblue;
}
.header-cta {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}
.header-socials {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.header-socials a {
  width: 1.8rem;
  aspect-ratio: 1/1;
  background: #ffa500;
  color: #000080;
  display: grid;
  place-items: center;
  border-radius: var(--border-radius-3);
}

.header-socials a:hover {
  background: #000080;
  color: #ffa500;
  transform: translateX(-0.5rem);
}

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
  header {
    margin-top: 0;
    height: 100vh;
  }
}

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 600px) {
  .header-profile {
    width: 14rem;
    height: 17rem;
    max-width: 60%;
    max-height: 35vh;
  }

  .header-container p {
    width: 100%;
  }

  .header-cta {
    gap: 1rem;
  }

  .header-socials {
    display: none;
  }
}
