.faqs-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem 4rem;
  align-items: start;
}

.card.faq {
  cursor: pointer;
  background: #000080;
}

.card.faq:hover {
  cursor: pointer;
  background: #000080;
}

.faq > div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 2rem;
}

.faq h5 {
  line-height: 1.4;
}

.faq-icon {
  font-size: 1.2rem;
  color: #ffa500;
  background: transparent;
}

.faq-answer {
  margin-top: 1rem;
  opacity: 0;
  animation: animateAnswer 500ms ease-in forwards;
}

@keyframes animateAnswer {
  to {
    opacity: 1;
  }
}

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
  .faqs-container {
    grid-template-columns: 1fr;
  }
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
  .faqs-container {
    gap: 1rem;
  }
}
