footer {
  padding-top: 8rem;
  box-shadow: 0 -1rem 1.5rem hsla(var(--primary-hue), 68%, 42%, 20%);
  transition: var(--transition);
}

.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-menu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-menu li {
  margin: 0 1rem;
}

.nav-menu a {
  color: #ffffff;
  text-decoration: none;
  transition: transform 0.3s ease;
}

.footer-socials {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin: 1rem auto 4rem;
}

.footer-socials a {
  background: #fcfcfc;
  color: #000080;
  padding: 0.6rem;
  border-radius: var(--border-radius-3);
}

.footer-socials a:hover {
  opacity: 0.7;
  transform: translateY(-0.5rem);
  background: #fff;
}

.footer-copyright {
  color: #fcfcfc;
  text-align: center;
  /* border-top: 2px solid rgba(255, 165, 0, 0.4); */
  padding: 1.5rem 0;
}

@media screen and (max-width: 600px) {
  .nav-menu {
    display: none;
  }
}
