.services-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 4rem;
  color: rgb(81, 80, 80);
}

.card.service {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  background-color: rgba(255, 255, 255, 0.3);
}

.service-icon {
  background: #ffa500;
  padding: 0.6rem;
  border-radius: var(--border-radius-3);
  font-size: 1.5rem;
  color: #000080;
  transition: var(--transition);
}

.service:hover .service-icon {
  background: #000080;
  color: #ffa500;
}

.service h4 {
  margin-bottom: 0.7rem;
}

/* MEDIA QUERIES (tablets) */
@media screen and (max-width: 1024px) {
  .services-container {
    gap: 1.5rem;
  }
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
  .services-container {
    grid-template-columns: 1fr;
    gap: 1.2rem;
  }

  .card.service {
    gap: 1.2rem;
  }
}
