.contact-container {
  width: fit-content;
  display: flex;
  justify-content: center;
  gap: 3rem;
}

.contact-container a {
  width: 5rem;
  aspect-ratio: 1/1;
  color: #ffa500;
  background: #000080;
  display: grid;
  place-items: center;
  font-size: 2rem;
  border: 2px solid transparent;
  border-radius: var(--border-radius-1);
}

.contact-container a:hover {
  background: transparent;
  border-color: #ffa500;
  color: #ffffff;
  transform: translateY(-0.5rem);
}

/* MEDIA QUERIES (phones) */
@media screen and (max-width: 600px) {
  .contact-container {
    gap: 1.5rem;
  }
}
