nav {
  width: 100%;
  display: flex;
  align-items: center;
  backdrop-filter: blur(5px); /* Adjust the blur amount as needed */
  transition: background-color 0.3s ease-in-out;
  position: fixed;
  top: 0;
  left: 20;
  z-index: 9;
  /* box-shadow: 0 1rem 1.5rem hsla(var(--primary-hue), 68%, 42%, 20%); */
  transition: var(--transition);
}

/* Navbar Container */
.nav_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
  width: 100%;
  /* background-color: rgba(
    255,
    165,
    0,
    0.9
  );  */
  gap: 150px;
  backdrop-filter: blur(5px); /* Adjust the blur amount as needed */
  transition: background-color 0.3s ease-in-out;
}

.nav_container.scrolled {
  background-color: rgba(255, 255, 255, 0.3);
}

/* Navbar Logo */
.nav_logo {
  width: 20rem;
  overflow: hidden;
  margin-top: 0.5rem;
}

/* Navbar Menu */
.nav_menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-grow: 1; /* Allow the menu to take remaining space */
  justify-content: center; /* Center the menu items */
}

.nav_menu li {
  margin-right: 2.7rem;
}

.nav_menu a {
  text-decoration: none;
  color: #000080;
  font-size: 0.9rem;
  transition: color 0.3s ease-in-out;
}

.nav_menu a:hover {
  color: #ffffff;
}

/* Theme Icon */
#theme__icon {
  background: transparent;
  color: #ffa500;
  font-size: 2rem;
  cursor: pointer;
}
#theme__icon:hover {
  color: #ffffff;
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
  .nav_menu {
    display: none;
  }
  .nav_logo {
    width: 15rem;
    overflow: hidden;
    margin-top: 0.5rem;
  }
}

.scrolled {
  background-color: rgba(
    0,
    0,
    128,
    0.5
  ); /* Change the background color when scrolled */
  box-shadow: 0 1rem 1.5rem hsla(var(--primary-hue), 68%, 42%, 20%);
}
